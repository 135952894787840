import apiClient from './apiService';
import { LoginCredentials, User } from '@/interfaces/User';
import store from '@/store';
import axios from 'axios';

class AuthService {
    async login(credentials: LoginCredentials): Promise<void> {
        try {
            const response = await apiClient.post<ApiResponse<User>>('/login', credentials);
            const user: User = response.data.data;
            localStorage.setItem('me', JSON.stringify(user));
            store.dispatch('currentUser/updateUser', user);
        } catch (error) {
            store.dispatch('currentUser/updateUser', null);
            localStorage.removeItem('me'); // Remove the user from local storage
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 401) {
                    throw new Error('Invalid login details');
                }
            }
            throw new Error('An unexpected error occurred during login');
        }
    }

    async getUser(): Promise<User | null> {
        try {
            const response = await apiClient.get<ApiResponse<User>>('/user');
            const user = response.data.data;
            localStorage.setItem('me', JSON.stringify(user));
            store.dispatch('currentUser/updateUser', user);
            return user;
        } catch (error) {
            store.dispatch('currentUser/updateUser', null);
            localStorage.removeItem('me'); // Remove the user from local storage
            return null;
        }
    }

    logout(): void {
        store.dispatch('currentUser/updateUser', null);
        localStorage.removeItem('me'); // Remove the user from local storage
        sessionStorage.clear();
        apiClient.post('/logout');
    }

    isAuthenticated(): boolean {
        return !!store.getters['currentUser/isAuthenticated'];
    }
}

export default new AuthService();