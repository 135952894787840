// store/index.ts
import { createStore } from 'vuex';
import crudModule from '@/store/modules/crudModule';
import usersModule from '@/store/modules/usersModule';
import platformsModule from './modules/platformsModule';
import examModule from '@/store/modules/examModule';
import examStudentsModule from '@/store/modules/examStudentsModule';
import currentUserModule from '@/store/modules/currentUserModule';
import schoolsModule from '@/store/modules/schoolsModule';

const store = createStore({
  modules: {
    platforms: platformsModule,

    crud: crudModule,
    currentUser: currentUserModule,
    usersManagement: usersModule,

    examForm: examModule,
    assignedStudents: examStudentsModule,
    
    schools: schoolsModule,
  },
});

export default store;
