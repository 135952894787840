<template>
  <div class="flex items-center gap-x-2 min-w-fit">

    <img v-if="!uploadedImageUrl && imageUrl" :src="imageUrl" class="h-10 w-10 object-cover rounded-lg" />
    <img v-if="uploadedImageUrl" :src="uploadedImageUrl" class="h-10 w-10 object-cover rounded-lg" />

    <a v-if="!uploadedImageUrl && imageUrl" :href="imageUrl" target="_blank" class="text-blue-500 text-xs">
      {{ $t("common.preview_image") }}
    </a>

    <a v-if="uploadedImageUrl" :href="uploadedImageUrl" target="_blank" class="text-blue-500 text-xs">
      {{ $t("common.preview_image") }}
    </a>

    <img v-if="uploadedImageUrl || imageUrl" @click.prevent="removeFile" src="@/assets/icons/upload-delete.svg" class="cursor-pointer w-5 h-5" :alt="$t('common.remove')" />  

    <Spinner v-if="isUploadingImage" class="w-5 h-5" />

    <div v-if="!selectedFile && !(uploadedImageUrl || imageUrl)" class="flex items-center justify-center gap-x-2">
      <label :for="inputId" class="cursor-pointer">
        <img src="@/assets/icons/upload.svg" class="w-5 h-5" />
      </label>
      <input :id="inputId" type="file" @change="handleFileSelection" accept="image/*" class="hidden" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MediaService from "@/services/mediaService";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "ImageUploader",
  props: {
    mediaLabel: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      default: null,
    },
  },
  data(props) {
    return {
      selectedFile: null as File | null,
      mediaLabel: props.mediaLabel,
      isUploadingImage: false,
      uploadedImageUrl: "",
      inputId: `image-input-${Math.random().toString(36).substring(7)}`,
      imageUrl: props.imageUrl,
      toast: useToast(),
    };
  },
  methods: {
    handleFileSelection(event: Event) {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        this.setSelectedFile(target.files[0]);
      }
    },
    setSelectedFile(file: File) {
      this.selectedFile = file;
      // upload the file
      this.uploadMedia();
    },
    removeFile() {
      this.selectedFile = null;
      this.uploadedImageUrl = "";
      this.$emit("image-deleted");
    },
    async uploadMedia() {
      if (!this.selectedFile || !this.mediaLabel) return;

      try {
        this.isUploadingImage = true;
        const result = await MediaService.uploadMedia(this.selectedFile, this.mediaLabel);

        if (result.status == true && result.data) {
          this.uploadedImageUrl = result.data.preview_url;
          this.toast.success(result.message);
          this.$emit("image-uploaded", result.data);
        } else {
          this.toast.error(result.message);
        }
      } catch (error) {
        console.error("Upload error:", error);
        this.toast.error("An error occurred during upload");
      } finally {
        this.isUploadingImage = false;
      }
    },
  },
});
</script>
