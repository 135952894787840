<template>
  <div class="max-w-72">
    <div v-if="!uploadedMediaUrl && mediaUrl" class="h-64 w-full min-w-64 object-cover mb-4 ring-2 ring-gray-300 rounded-3xl p-4 shadow-lg">
      <div class="flex flex-col items-center p-8 relative">
        <button class="absolute top-1 end-1" @click="removeUploadedFile" v-if="!previewOnly">
          <img src="@/assets/icons/delete.svg" class="w-6 h-6 cursor-pointer hover:bg-gray-300 hover:rounded-full" />
        </button>
        <a :href="mediaUrl" target="_blank" class="text-indigo-500 text-center">
          <img src="@/assets/icons/file.svg" class="w-32 h-32 mb-2" />
          {{ $t("common.preview") }}
        </a>
      </div>
    </div>
    <div v-if="uploadedMediaUrl" class="h-64 w-full min-w-64 object-cover mb-4 ring-2 ring-gray-300 rounded-3xl p-4 shadow-lg">
      <div class="flex flex-col items-center p-8 relative">
        <button class="absolute top-1 end-1" @click="removeUploadedFile" v-if="!previewOnly">
          <img src="@/assets/icons/delete.svg" class="w-6 h-6 cursor-pointer hover:bg-gray-300 hover:rounded-full" />
        </button>
        <a :href="uploadedMediaUrl" target="_blank" class="text-indigo-500 text-center">
          <img src="@/assets/icons/file.svg" class="w-32 h-32 mb-2" />
          {{ $t("common.preview") }}
        </a>
      </div>
    </div>
    <div v-if="previewUrl" class="h-64 w-full min-w-64 object-cover mb-4 ring-2 ring-gray-300 rounded-3xl p-4 shadow-lg">
      <div class="flex flex-col items-center p-8">
        <a :href="previewUrl" target="_blank" class="text-indigo-500 text-center">
          <img src="@/assets/icons/file.svg" class="w-32 h-32 mb-2" />
          {{ $t("common.preview") }}
        </a>
      </div>
    </div>

    <label :for="uniqueId" class="block">
      <div v-if="!selectedFile && !previewOnly && !mediaUrl" class="border-2 border-dashed border-gray-300 rounded-md p-5 text-center cursor-pointer transition-colors duration-300 min-w-64" :class="{ 'bg-gray-100': isDragging }" @dragover.prevent @drop.prevent="handleDrop" @dragenter.prevent="isDragging = true" @dragleave.prevent="handleDragLeave">
        <div class="flex flex-col items-center p-8">
          <i class="mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="17 8 12 3 7 8"></polyline>
              <line x1="12" y1="3" x2="12" y2="15"></line>
            </svg>
          </i>
          <p>{{ $t("common.drag_and_drop") }}</p>
          <p>{{ $t("common.or") }}</p>
          <p class="bg-transparent font-bold cursor-pointer hover:bg-gray-100 rounded-xl p-1">{{ $t("common.browse") }}</p>
        </div>
      </div>
      <input :id="uniqueId" type="file" @change="handleFileSelection" accept=".doc,.docx,.pdf,.png,.jpg,.jpeg" class="hidden" />
    </label>

    <a v-if="!previewOnly && !uploadedMediaUrl" @click.prevent="uploadMedia" :class="{ 'opacity-50 cursor-not-allowed': !selectedFile || !mediaLabel, 'cursor-pointer': selectedFile && mediaLabel }" class="block w-full mt-3 py-2 px-4 bg-indigo-500 text-white text-center rounded-md">
      {{ $t("common.upload") }}
    </a>

    <a v-if="!previewOnly && !uploadedMediaUrl" @click.prevent="removeFile" v-show="selectedFile && mediaLabel" :class="{ 'opacity-50 cursor-not-allowed': !selectedFile || !mediaLabel, 'cursor-pointer': selectedFile && mediaLabel }" class="block w-full mt-3 py-2 px-4 bg-red-600 text-white text-center rounded-md">
      {{ $t("common.remove") }}
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MediaService from "@/services/mediaService";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "MediaUploader",
  props: {
    mediaLabel: {
      type: String,
      required: true,
    },
    mediaUrl: {
      type: String,
      default: "",
    },
    previewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data(props) {
    return {
      selectedFile: null as File | null,
      mediaLabel: props.mediaLabel,
      uploadedMediaUrl: "",
      isDragging: false,
      previewUrl: "",
      mediaUrl: props.mediaUrl,
      toast: useToast(),
      uniqueId: `file-input-${Math.random().toString(36).substring(2, 15)}`, // Generate a unique ID
    };
  },
  methods: {
    handleFileSelection(event: Event) {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        this.setSelectedFile(target.files[0]);
      }
    },
    handleDrop(event: DragEvent) {
      this.isDragging = false;
      if (event.dataTransfer?.files.length) {
        this.setSelectedFile(event.dataTransfer.files[0]);
      }
    },
    handleDragLeave(event: DragEvent) {
      if (!event.relatedTarget || !(event.currentTarget as Node).contains(event.relatedTarget as Node)) {
        this.isDragging = false;
      }
    },
    setSelectedFile(file: File) {
      this.selectedFile = file;
      this.previewUrl = URL.createObjectURL(file);
    },
    removeFile() {
      this.selectedFile = null;
      this.previewUrl = "";
    },
    removeUploadedFile() {
      // this will only emit an event to remove the media from the parent component.
      this.uploadedMediaUrl = "";
      this.$emit("media-removed");
    },
    async uploadMedia() {
      if (!this.selectedFile || !this.mediaLabel) return;

      try {
        const result = await MediaService.uploadMedia(this.selectedFile, this.mediaLabel);

        if (result.status == true && result.data) {
          this.uploadedMediaUrl = result.data.preview_url;
          this.toast.success(result.message);
          this.removeFile();
          this.$emit("media-uploaded", result.data);
        } else {
          this.toast.error(result.message);
        }
      } catch (error) {
        console.error("Upload error:", error);
        this.toast.error(error);
      }
    },
  },
});
</script>
