<template>
  <div class="flex items-center justify-center gap-x-2">

    <Spinner v-if="isUploadingAudio" class="w-5 h-5" />

    <div class="flex items-center justify-center gap-x-2" v-else>
      <label :for="inputId" class="cursor-pointer">
        <img src="@/assets/icons/audio.svg" class="min-w-5 h-5" />
      </label>
      <input :id="inputId" type="file" @change="handleFileSelection" accept="audio/wav,audio/mp3" class="hidden" />

      <div v-if="audioSource" class="flex justify-center gap-x-2">
        <button @click.prevent="toggleAudio" class="p-2 bg-green-500 text-white rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <polygon v-if="!isPlaying" points="5 3 19 12 5 21 5 3"></polygon>
            <rect v-else x="6" y="6" width="12" height="12"></rect>
          </svg>
        </button>
        <button @click.prevent="deleteAudio" class="p-2 bg-red-500 text-white rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
          </svg>
        </button>
      </div>

      <audio ref="audioPlayer" :src="audioSource" @ended="isPlaying = false" class="hidden"></audio>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";
import MediaService from "@/services/mediaService";

export default defineComponent({
  name: "AudioUploader",
  emits: ["audio-uploaded", "audio-deleted"],
  props: {
    mediaLabel: {
      type: String,
      required: true,
    },
    audioUrl: {
      type: String,
      default: null,
    },
  },
  data(props) {
    return {
      selectedFile: null as File | null,
      uploadedAudioUrl: "",
      uploadStatus: "",
      isUploadingAudio: false,
      inputId: `audio-input-${Math.random().toString(36).substring(7)}`,
      isPlaying: false,
      audioLabel: props.audioLabel,
      toast: useToast(),
    };
  },
  computed: {
    audioSource(): string {
      return this.uploadedAudioUrl || this.audioUrl;
    },
  },
  methods: {
    handleFileSelection(event: Event) {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        this.selectedFile = target.files[0];
        this.uploadAudio();
      }
    },
    async uploadAudio() {
      if (!this.selectedFile) return;

      try {
        this.isUploadingAudio = true;
        const result = await MediaService.uploadMedia(this.selectedFile, this.mediaLabel);
        if (result.status === true && result.data) {
          this.uploadedAudioUrl = result.data.preview_url;
          this.$emit("audio-uploaded", result.data);
          this.toast.success(result.message);
        } else {
          this.uploadStatus = result.message || "Upload failed";
          this.toast.error(result.message);
        }
      } catch (error) {
        this.uploadStatus = "An error occurred during upload";
        console.error("Upload error:", error);
        this.toast.error("An error occurred during upload");
      } finally {
        this.isUploadingAudio = false;
      }
    },
    toggleAudio() {
      const audioPlayer = this.$refs.audioPlayer as HTMLAudioElement;
      if (this.isPlaying) {
        audioPlayer.pause();
      } else {
        audioPlayer.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    stopAudio() {
      const audioPlayer = this.$refs.audioPlayer as HTMLAudioElement;
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
      this.isPlaying = false;
    },
    deleteAudio() {
      this.stopAudio();
      this.uploadedAudioUrl = "";
      this.$emit("audio-deleted");
    },
  },
  beforeUnmount() {
    this.stopAudio();
  },
});
</script>
