import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useToast } from 'vue-toastification';
import MainLayout from '@/components/common/MainLayout.vue';
import AuthLayout from '@/components/common/AuthLayout.vue';
import i18n from '@/i18n';
import authService from '@/services/authService';
import store from '@/store';

// Define the route records
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: AuthLayout,
    children: [{ path: 'login', name: 'Login', component: () => import('@/views/auth/LoginView.vue'), meta: { requiresAuth: false, title: 'page_titles.login' } },]
  },
  { path: '/restricted', name: 'Restricted', component: () => import('@/views/RestrictedView.vue'), meta: { title: 'page_titles.restricted' } },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/views/NotFoundView.vue'), meta: { title: 'page_titles.not_found' } },
  {
    path: '',
    component: MainLayout,
    meta: { requiresAuth: true },
    children: [
      { path: '/profile', name: 'Profile', component: () => import('@/views/ProfileView.vue'), meta: { title: 'page_titles.profile' } },

      { path: '', redirect: '/exams' }, // Redirect to exams page by default.
      { path: '/exams', name: 'Home', component: () => import('@/views/exams/ExamList.vue'), meta: { title: 'page_titles.home', permission: 'com.ltm.permissions.exams.view.exam' } },
      { path: '/exams/create', name: 'NewExam', component: () => import('@/views/exams/ExamForm.vue'), meta: { title: 'page_titles.new_exam', permission: 'com.ltm.permissions.exams.create.exam' } },
      { path: '/exams/:id/edit', name: 'EditExam', component: () => import('@/views/exams/ExamForm.vue'), props: true, meta: { title: 'page_titles.edit_exam', permission: 'com.ltm.permissions.exams.edit.exam' } },
      { path: '/exams/:id/preview', name: 'PreviewExam', component: () => import('@/views/exams/PreviewExam.vue'), props: true, meta: { title: 'page_titles.preview_exam', permission: 'com.ltm.permissions.exams.view.exam' } },
      { path: '/exams/:id/preview/students', name: 'PreviewExamStudent', component: () => import('@/views/exams/ExamPreviewStudents.vue'), props: true, meta: { title: 'page_titles.preview_exam_student', permission: 'com.ltm.permissions.exams.preview.exam.students' } },
      { path: '/exams/:exam_id/preview/students/:student_id/correction', name: 'ExamCorrection', component: () => import('@/views/exams/ExamStudentCorrection.vue'), props: true, meta: { title: 'page_titles.exam_correction', permission: 'com.ltm.permissions.exams.view.student.exam.responses' } },

      // Reports Permission currently specific for schools only since it's the only entity that has reports interaction.
      { path: '/reports', name: 'Reports', component: () => import('@/views/reports/SchoolsReportingView.vue'), meta: { title: 'page_titles.reports', permission: 'com.ltm.permissions.schools.view.school' } },

      { path: '/questions-bank', name: 'QuestionsBank', component: () => import('@/views/bank/QuestionsBankList.vue'), meta: { title: 'page_titles.questions_bank', permission: 'com.ltm.permissions.questions.bank.view.question' } },
      { path: '/questions-bank/create', name: 'NewBankQuestion', component: () => import('@/views/bank/QuestionBankForm.vue'), meta: { title: 'page_titles.new_question', permission: 'com.ltm.permissions.questions.bank.create.question' } },
      { path: '/questions-bank/:id/edit', name: 'EditBankQuestion', component: () => import('@/views/bank/QuestionBankForm.vue'), props: true, meta: { title: 'page_titles.edit_question', permission: 'com.ltm.permissions.questions.bank.view.question' } },

      { path: '/settings/platforms', name: 'Platforms', component: () => import('@/views/store/SystemSettingsView.vue'), meta: { title: 'page_titles.platforms', permission: 'com.ltm.permissions.platforms.view.platform' } },
      { path: '/settings/users', name: 'Users', component: () => import('@/views/users/UsersView.vue'), meta: { title: 'page_titles.users_management', permission: 'com.ltm.permissions.users.view.users' } },

      { path: '/settings/exams/skills', name: 'Skills', component: () => import('@/views/store/SkillsView.vue'), meta: { title: 'page_titles.skills', permission: 'com.ltm.permissions.skills.view.skill' } },
      { path: '/settings/exams/rounds', name: 'Rounds', component: () => import('@/views/store/RoundsView.vue'), meta: { title: 'page_titles.rounds', permission: 'com.ltm.permissions.rounds.view.round' } },
      { path: '/settings/exams/grades', name: 'Grades', component: () => import('@/views/store/GradesView.vue'), meta: { title: 'page_titles.grades', permission: 'com.ltm.permissions.grades.view.grade' } },
      { path: '/settings/exams/levels', name: 'Levels', component: () => import('@/views/store/LevelsView.vue'), meta: { title: 'page_titles.levels', permission: 'com.ltm.permissions.levels.view.level' } },
      { path: '/settings/exams/durations', name: 'Durations', component: () => import('@/views/store/DurationsView.vue'), meta: { title: 'page_titles.durations', permission: 'com.ltm.permissions.durations.view.duration' } },

      { path: '/settings/questions/cognitive-levels', name: 'CognitiveLevels', component: () => import('@/views/store/CognitiveLevelsView.vue'), meta: { title: 'page_titles.cognitive_levels', permission: 'com.ltm.permissions.cognitive.levels.view.cognitive.level' } },
      { path: '/settings/questions/difficulty-levels', name: 'DifficultyLevels', component: () => import('@/views/store/DifficultyLevelsView.vue'), meta: { title: 'page_titles.difficulty_levels', permission: 'com.ltm.permissions.difficulty.levels.view.difficulty.level' } },
      { path: '/settings/questions/learning-outcomes', name: 'LearningOutcomes', component: () => import('@/views/store/LearningOutcomesView.vue'), meta: { title: 'page_titles.learning_outcomes', permission: 'com.ltm.permissions.learning.outcomes.view.learning.outcome' } },
      { path: '/settings/questions/depth-of-knowledge', name: 'DepthOfKnowledge', component: () => import('@/views/store/DepthOfKnowledgeView.vue'), meta: { title: 'page_titles.depth_of_knowledges', permission: 'com.ltm.permissions.depth.of.knowledges.view.depth.of.knowledge' } },
    ]
  }
];

// Create the router instance and pass the `routes` option
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

/**
 * Navigation guard to check if the user is authenticated before each route change.
 * @param to - the target Route object being navigated to
 * @param from - the current route being navigated away from
 * @param next - a function that must be called to resolve the hook
 */
router.beforeEach(async (to, from, next) => {
  const { t } = i18n.global;
  const toast = useToast();

  const pageTitle = to.meta.title ? t(to.meta.title as string) : t('site.title');
  document.title = `${pageTitle} | ${t('site.title')}`;

  if (to.meta.requiresAuth !== false) {
    try {
      const storedUser = localStorage.getItem('me');

      if (!storedUser) {
        authService.logout();
        next({ name: 'Login' });
        return;
      }

      // Parse storedUser to an object
      const user = JSON.parse(storedUser);

      // Dispatch user to Vuex
      store.dispatch('currentUser/updateUser', user);

      const basePermission = 'com.ltm.permissions.platforms.view.platform'; // Base permission to access the system.
      const requiredPermission = to.meta.permission;
      const hasBasePermission = user.permissions.some((p: any) => p.permission === basePermission);

      if (!hasBasePermission) { // If the user does not have the base permission, logout and redirect to login page.
        authService.logout();
        next({ name: 'Login' });
        toast.error(t('user.login.permission_denied'));
        return;
      }

      if (requiredPermission && !user.permissions.some((p: any) => p.permission === requiredPermission)) {
        next({ name: 'Restricted' });
        return;
      }

      next();
    } catch (error) {
      next({ name: 'Login' });
    }
  } else {
    next();
  }
});



export default router;