<template>
  <header class="bg-white">
    <nav class="flex items-center justify-between py-2 px-12 md:shadow-md md:rounded-b-2xl" aria-label="Global">
      <div class="flex lg:flex-1">
        <a href="/" class="-m-1.5 p-1.5">
          <span class="sr-only">
            {{ $t("site.title") }}
          </span>
          <img class="h-16 w-auto" src="@/assets/images/logo.png" :alt="$t('site.title')" />
        </a>
      </div>
      <div class="flex gap-x-6 justify-end items-center">
        <a v-can="'com.ltm.permissions.exams.view.exam'" href="/exams" class="text-sm font-semibold leading-6 text-gray-900" :class="{ 'text-indigo-600 pointer-events-none': $route.path === '/exams' }">{{ $t("exams.title") }}</a>
        <!-- Reports Permission currently specific for schools only since it's the only entity that has reports interaction. -->
        <a v-can="'com.ltm.permissions.schools.view.school'" href="/reports" class="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-600" :class="{ 'text-indigo-600 pointer-events-none': $route.path === '/reports' }">{{ $t("reports.title") }}</a>
        <a v-can="'com.ltm.permissions.questions.bank.view.question'" href="/questions-bank" class="text-sm font-semibold leading-6 text-gray-900" :class="{ 'text-indigo-600 pointer-events-none': $route.path === '/questions-bank' }">{{ $t("questions_bank.title") }}</a>

        <div class="relative" v-can="'com.ltm.permissions.settings.view.settings'">
          <button @click="toggleSettingsDropdown" class="text-sm font-semibold leading-6 text-gray-900 flex items-center" :class="{ 'text-indigo-600': $route.path.includes('/settings') }">
            {{ $t("store.settings") }}
            <img class="h-4 w-4 ms-1" src="@/assets/icons/arrow-down-dropdown.svg" alt="arrow-down" />
          </button>
          <div v-show="showSettingsDropdown" class="absolute end-0 mt-2 py-2 w-48 bg-white rounded-lg shadow-2xl z-20 border">
            <!-- Exams -->
            <h3 class="px-4 py-2 text-sm text-gray-700 font-bold">{{ $t("store.exams_settings") }}</h3>
            <a v-can="'com.ltm.permissions.skills.view.skill'" href="/settings/exams/skills" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/exams/skills') }">{{ $t("store.skills") }}</a>
            <a v-can="'com.ltm.permissions.rounds.view.round'" href="/settings/exams/rounds" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/exams/rounds') }">{{ $t("store.rounds") }}</a>
            <a v-can="'com.ltm.permissions.grades.view.grade'" href="/settings/exams/grades" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/exams/grades') }">{{ $t("store.grades") }}</a>
            <a v-can="'com.ltm.permissions.levels.view.level'" href="/settings/exams/levels" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/exams/levels') }">{{ $t("store.levels") }}</a>
            <a v-can="'com.ltm.permissions.durations.view.duration'" href="/settings/exams/durations" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/exams/durations') }">{{ $t("store.durations") }}</a>

            <hr class="border-gray-200 my-2" />
            <!-- Questions -->
            <h3 class="px-4 py-2 text-sm text-gray-700 font-bold">{{ $t("store.questions_settings") }}</h3>
            <a v-can="'com.ltm.permissions.cognitive.levels.view.cognitive.level'" href="/settings/questions/cognitive-levels" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/questions/cognitive-levels') }">{{ $t("store.cognitive-levels") }}</a>
            <a v-can="'com.ltm.permissions.difficulty.levels.view.difficulty.level'" href="/settings/questions/difficulty-levels" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/questions/difficulty-levels') }">{{ $t("store.difficulty-levels") }}</a>
            <a v-can="'com.ltm.permissions.learning.outcomes.view.learning.outcome'" href="/settings/questions/learning-outcomes" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/questions/learning-outcomes') }">{{ $t("store.learning-outcomes") }}</a>
            <a v-can="'com.ltm.permissions.depth.of.knowledges.view.depth.of.knowledge'" href="/settings/questions/depth-of-knowledge" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/questions/depth-of-knowledge') }">{{ $t("store.depth-of-knowledges") }}</a>

            <hr v-has-role="'com.ltm.roles.admin'" class="border-gray-200 my-2" />
            <!-- Platforms -->
            <h3 v-has-role="'com.ltm.roles.admin'" class="px-4 py-2 text-sm text-gray-700 font-bold">{{ $t("store.system_settings") }}</h3>
            <a v-has-role="'com.ltm.roles.admin'" v-can="'com.ltm.permissions.platforms.view.platform'" href="/settings/platforms" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/platforms') }">{{ $t("store.platforms") }}</a>
            <a v-has-role="'com.ltm.roles.admin'" v-can="'com.ltm.permissions.users.view.users'" href="/settings/users" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/users') }">{{ $t("users_management.title") }}</a>
          </div>
        </div>

        <div class="relative" v-if="isAuthenticated">
          <button @click="toggleUserDropdown" class="text-sm font-semibold leading-6 text-gray-900 flex items-center" :class="{ 'text-indigo-600': toggleUserDropdown || $route.path.includes('/settings') }">
            <img class="h-7 w-7" src="@/assets/icons/avatar.svg" :alt="user.name" />
          </button>
          <div v-if="showUserDropdown" class="absolute end-0 mt-2 w-48 bg-white rounded-lg shadow-2xl z-20 border">
            <router-link to="/profile" class="hover:cursor-pointer" dir="ltr" :class="{ 'pointer-events-none': $route.path === '/profile' }">
              <div class="px-4 py-4 hover:bg-indigo-50" @click="toggleUserDropdown" :class="{ 'bg-indigo-50': $route.path === '/profile' }">
                <p class="text-sm text-gray-700 font-bold">{{ user.name }}</p>
                <p class="text-xs text-indigo-700">{{ user.roles.map((p) => p.name).join(", ") }}</p>
                <p class="text-sm text-gray-700">{{ user.email }}</p>
              </div>
            </router-link>
            <hr class="border-gray-200" v-if="user" />
            <ButtonConfirmation @confirm="logoutUser" buttonClass="w-full text-start px-4 py-4 text-sm text-red-500 hover:bg-gray-100">
              {{ $t("user.logout.title") }}
            </ButtonConfirmation>
          </div>
        </div>

        <LanguageSwitcher />
      </div>
    </nav>
  </header>
</template>

<script setup>
import { ref } from "vue";
import { useCurrentUser } from "@/composables/auth/useCurrentUser";
import LanguageSwitcher from "@/components/common/LanguageSwitcher.vue";

const showSettingsDropdown = ref(false);
const showUserDropdown = ref(false);
const { user, isAuthenticated, clearUser, logoutUser } = useCurrentUser();

const toggleSettingsDropdown = () => {
  showSettingsDropdown.value = !showSettingsDropdown.value;
  showUserDropdown.value = false;
};
const toggleUserDropdown = () => {
  showUserDropdown.value = !showUserDropdown.value;
  showSettingsDropdown.value = false;
};
</script>

<style scoped></style>
