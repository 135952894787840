import apiClient from './apiService'
import { School, SchoolQueryParams } from '../interfaces/School'

export default {
    async getAllSchools(params?: SchoolQueryParams): Promise<ApiResponse<School[]>> {
        const response = await apiClient.get<ApiResponse<School[]>>('/schools', { params })
        return response.data
    },

    async migrateSchoolReports(school_id: number): Promise<ApiResponse<School>> {
        const response = await apiClient.post<ApiResponse<School>>('/schools/migrate/reports', { school_id })
        return response.data
    }
}